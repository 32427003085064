@tailwind base;
@tailwind components;
@tailwind utilities;


@media print {
    .noprint {
       visibility: hidden;
    }
 }

.page-footer,
.page-footer-space {
    height: 60px;

}

.page-footer {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    visibility: hidden;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;

}

.page-container {
    width: 21cm;
    padding-left: 25px;
    padding-right: 25px;
}

.page {
    page-break-after: always;
    min-height: 29.7cm;
    border: 1px solid rgba(0, 0, 0, 0.304);
    padding: 20px 20px;
    margin-bottom: 0.5cm;

}

@page {
    margin: 20mm
}

@media print {
    .page-container {
        padding-left: 10px;
        padding-right: 25px;
    }

    .page {
        border: none;

    }

    .page-footer {
        visibility: visible !important;
    }

    tfoot {
        display: table-footer-group;
    }

    body {
        margin: 0;
    }
}

.hidden-scrollbar::-webkit-scrollbar{
   display: none !important;
}